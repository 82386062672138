import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => {
  const d = data.allContentfulAboutUs.nodes[0]
  const title = d.aboutUsTitle
  const desc = d.seoDescription
  const content = d.content.childMarkdownRemark.html
  const imageBig = d.imageBig.file.url
  const imageBig2 = d.imageBig2.file.url
  const imageBig3 = d.imageBig3.file.url
  const titleBig = d.imageBig.title
  const titleBig2 = d.imageBig2.title
  const titleBig3 = d.imageBig3.title

  return (
    <Layout>
      <SEO title={title} description={desc} />
      <h1 className="mb-4">{title}</h1>
      <div className="mx-6 max-w-6xl lg:mx-auto my-6 bg-white rounded-lg shadow-light">
        <div className="xl:mx-auto p-6 bg-white">
          <div
            className="px-6 text-black text-justify min-h-full text-xs"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
        <div className="mx-auto flex flex-col max-w-2xl py-6 fling-minislide">
          <img src={imageBig} alt={titleBig} className="py-2" />
          <img src={imageBig2} alt={titleBig2} className="py-2" />
          <img src={imageBig3} alt={titleBig3} className="py-2" />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulAboutUs {
      nodes {
        seoDescription
        aboutUsTitle
        imageBig {
          title
          file {
            url
          }
        }
        imageBig2 {
          title
          file {
            url
          }
        }
        imageBig3 {
          title
          file {
            url
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default AboutPage
